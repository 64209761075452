import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Project from "../components/project"
import Seo from "../components/seo"

const ProjectTemplate = (data) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            slogan
            description
            author
            siteUrl
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Seo
        title={data.pageContext.title}
        description={data.pageContext.description}
        url={site.siteMetadata.siteUrl + "/" + data.pageContext.slug}
      />
      <Helmet>
        <meta
          property="og:image"
          content={
            site.siteMetadata.siteUrl +
            "/share/" +
            data.pageContext.key +
            "-hd.png"
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Project projectKey={data.pageContext.key} />
    </Layout>
  )
}

export default ProjectTemplate
